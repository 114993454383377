import MainHeader from '../../components/mainHeader/Index.vue'
import MainFooter from '../../components/mainFooter/Index.vue'
import Dict from '../../components/dict/Index.vue'
import { getProfileById,getDataFromGalxe } from '@/api/talent'
import { getRankData, getWeb3, _getGasPrice, _checkApproval2, popScanData  } from '../../utils/web3'
import BigNumber from "bignumber.js";
import config from "@/config";
import { Icon } from '@iconify/vue2'
export default {
  components: { MainHeader, MainFooter, Dict,Icon },
  data(){
    return{
      talentId: '',
      talent:{},
      rankData:{},
      popScanData:[],
      popScanDataLoading:false,
      galxeData:[],
      galxeDataLoading:false,
      hasPurchasedCV: false,
      currentAccount:null,
      cvPrice:1,
      createInit:true,
      balance:null,
      balanceInit:false
    }
  },
  filters: {
    formatScore(param){
      if(param)
        return param.toFixed(2)
    }
  },
  watch: {
    async currentAccount(newVal, oldVal){
      this.hasPurchasedCV=false
      if(this.createInit){
        this.createInit = false
        return;
      }
      console.log(newVal)
      if(newVal != oldVal){
        if(newVal != null)
        {
          this.balanceInit=false
          this.queryBalance()
          this.hasPurchasedCV =await this.checkHasPurchasedCV(this.talent.cnxtToken)
        }else{
          this.balance = null
        }
      }
    }
  },
  created(){
    this.initCvPrice()
  },
  mounted() {
    this.talentId = this.$route.query.talentId || ''
    if(this.talentId!='')
    {
      this.queryProfile()
    }
  },
  methods: {
    async queryBalance(){
      const that = this
      const web3 = await getWeb3()
      if(that.currentAccount != null)
      {
        const connextERCContract = new web3.eth.Contract(config.connextErc20ABI, config.connextErc20Address)
        const balance = await connextERCContract.methods.balanceOf(that.currentAccount).call()
        that.balance = new BigNumber(balance)
        .dividedBy(new BigNumber(10).pow(18))
        .toFixed(0, BigNumber.ROUND_DOWN)
      }

      that.balanceInit = true
    },
    toRouter(path){
      this.$router.push({name:path})
    },
    queryProfile(){
      const that = this
      if(this.talentId != null){
        getProfileById(this.talentId).then(async(resp) => {
          that.talent = resp.data
          // getRankData('0x9c5083dd4838e120dbeac44c052179692aa5dac5').then((rankData) => {
          //   that.rankData = rankData
          // })
          getRankData(that.talent.cnxtToken).then((rankData) => {
            that.rankData = rankData
          })
          that.popScanDataLoading = true
          popScanData(that.talent.cnxtToken).then((popScanData) => {
            that.popScanData = popScanData
            that.popScanDataLoading = false
          })
          that.galxeDataLoading = true
          getDataFromGalxe(that.talent.cnxtToken).then((resp) => {
            that.galxeData = resp.data
            that.galxeDataLoading = false
          })
          that.hasPurchasedCV = await that.checkHasPurchasedCV(that.talent.cnxtToken)
        });
      }
    },
    async checkHasPurchasedCV(talentToken){
      if(this.currentAccount == null)
        return false
      const web3 = await getWeb3()
      const connextAppContract = new web3.eth.Contract(config.connextAppABI, config.connextAppAddress)
      const hasPurchasedCVResult = await connextAppContract.methods.hasPurchasedCV(this.currentAccount,talentToken).call()

      return hasPurchasedCVResult
    },
    async initCvPrice(){
      const that = this
      const web3 = await getWeb3()
      const connextAppContract = new web3.eth.Contract(config.connextAppABI, config.connextAppAddress)
      const cvPrice = await connextAppContract.methods.cvPrice().call()
      that.cvPrice = new BigNumber(cvPrice)
      .dividedBy(new BigNumber(10).pow(18))
      .toFixed(0, BigNumber.ROUND_DOWN)
    },
    async unlock(talentId,talentToken,event){
      const target = event.currentTarget
      if(event.currentTarget.disabled)
        return
      event.currentTarget.disabled=true
      const loading = this.$loading({
        target: event.currentTarget,
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if(this.currentAccount == null){
        this.$message({
          showClose: true,
          message: 'Please connect wallet first!',
          type: 'error'
        })
        event.currentTarget.disabled=false
        loading.close()
        return
      }
      const that = this
      const web3 = await getWeb3()
      const connextAppContract = new web3.eth.Contract(config.connextAppABI, config.connextAppAddress)

      let amountToSend = await connextAppContract.methods.cvPrice().call()
      console.log(amountToSend)
      
      let checkResult = await _checkApproval2(this.currentAccount, talentToken, amountToSend);
      if(!checkResult){
        target.disabled=false
        loading.close();
        return;
      }
      connextAppContract.methods.purchaseCV(talentToken, amountToSend).send({ from: this.currentAccount, gasPrice: web3.utils.toWei(await _getGasPrice(), 'gwei')})
      .on('transactionHash', function(hash){
        console.log(hash);
      })
      .on('confirmation', function(confirmationNumber, receipt){
        console.log(receipt);
        console.log(confirmationNumber)
        that.hasPurchasedCV=true
        target.disabled=false
        loading.close();
      })
      .on('receipt', function(receipt){
        console.log(receipt);
      })
      .on('error', function(error) {
        target.disabled=false
        loading.close();
        // that.$message({
        //   showClose: true,
        //   message: error,
        //   type: 'error'
        // })
        console.log(error)
      })
      
    },
    open(link) {
      window.open(link,'_blank');
    }
  }
};